import {
  Company,
  createAlumniEducation,
  createAlumniPortfolio,
  createAlumniPortfolioImage,
  createAlumniPreferences,
  createAlumniWorkExperience,
  deleteAlumniEducation,
  deleteAlumniPortfolio,
  deleteAlumniPortfolioImage,
  deleteAlumniWorkExperience,
  getAlumniEducation,
  getAlumniPortfolio,
  getAlumniPreferences,
  getAlumniProfile,
  getAlumniWorkExperience,
  getRecruiterProfile,
  IAlumniProfile,
  IEducation,
  Image,
  IPortfolio,
  IPreference,
  IRecruiterProfile,
  IWorkExperience,
  updateAlumniEducation,
  updateAlumniPortfolio,
  updateAlumniPortfolioImage,
  updateAlumniPreferences,
  updateAlumniProfile,
  updateAlumniWorkExperience,
  updateRecruiterCompany,
  updateRecruiterProfile,
  uploadAlumniResume,
  changePassword,
  getAdminProfile,
  IAdminProfile,
  PasswordDTO,
  updateAdminProfile,
} from '@/apis/profileApis'

import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { PaginatedData } from '@/apis/api.types'
import { buildQueries } from '@testing-library/react'
import { DashboardData, getDashboardData } from '@/apis/dashboardApis'
import {
  RecruitersList,
  RecruitersListDTO,
  getBlockedRecruiterList,
  getRecruiter,
  getRecruiterList,
  getProfileCompletionValue,
} from '@/apis/recruitersApis'
import { BlockUserDTO, blockUser } from '@/apis/authApis'

export const recruitersListSlice = createApi({
  reducerPath: 'recruitersListApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Recruiters', 'BlockedRecruiters'],

  endpoints: (builder) => ({
    getRecruitersList: builder.query<
      PaginatedData<IRecruiterProfile>,
      RecruitersListDTO
    >({
      queryFn: async (data) => {
        try {
          return await getRecruiterList(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Recruiters'],
    }),
    getRecruiter: builder.query<IRecruiterProfile, number>({
      queryFn: async (id) => {
        try {
          return await getRecruiter(id)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Recruiters'],
    }),
    blockUser: builder.mutation<{ data: BlockUserDTO }, BlockUserDTO>({
      queryFn: async (data) => {
        try {
          return await blockUser(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Recruiters'],
    }),
    getBlockedRecruiterList: builder.query<
      PaginatedData<RecruitersList>,
      RecruitersListDTO
    >({
      queryFn: async (data) => {
        try {
          return await getBlockedRecruiterList(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['BlockedRecruiters'],
    }),
  }),
})
export const {
  useGetRecruitersListQuery,
  useGetBlockedRecruiterListQuery,
  useGetRecruiterQuery,
  useBlockUserMutation,
} = recruitersListSlice

export const resetrecruitersListSlice = () =>
  recruitersListSlice.util.resetApiState()
