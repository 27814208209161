import { addDataToQueryString } from './../helpers/strings'
// import { addDataToQueryString } from './../helpers/strings'
import { PaginatedData } from './api.types'
import api from './api'
import {
  ADMIN_PROFILE,
  ALUMNI_PROFILE,
  ALUMNI_WORK_EXPERIENCE,
  CHANGE_PASSWORD,
  RECRUITER_COMPANY,
  RECRUITER_PROFILE,
  GET_ALL_INDUSTRIES,
} from './endpoints'

export type ProfilePageDTO = {
  page?: number
  id?: number
}
export interface IAdminProfile {
  id: number
  user_id: number
  first_name: string
  last_name: string
  email: string
  email_verified: string
  phone_number: string
  phone_verified: string
  profile_pic: string
  date_joined: string
  online: boolean
  user_type: string
  admin_role: string
  permissions: Permission[]
  permission_role: string
  role: string
  preferred_language?: string
}

interface Permission {
  id: number
  name: string
}
export interface IRecruiterProfile {
  id: number
  user_id: number
  first_name: string
  last_name: string
  email: string
  email_verified: string
  phone_number: string
  phone_verified: string
  profile_pic: string
  date_joined: string
  online: boolean
  user_type: string
  recruiter_role: string
  company: Company
  company_role: string
  banner: string
  activity?: IActivity
  average_rating?: IRecruiterRating
  preferred_language?: string
  profile_complete: boolean | undefined
}

export interface IRecruiterProfileComplete {
  profile_complete: boolean | undefined
}

export interface IRecruiterRating {
  count: number
  average: number
}

interface IActivity {
  num_hired: number
  hiring_rate: number
  num_rejected: number
  rejection_rate: number
}

export interface Company {
  id: number
  total_engaged_alumni: number
  total_hired_alumni: number
  total_jobs_posted: number
  logo?: any
  name: string
  registration_number: string
  industry: string
  roles_hiring: string[]
  email: string
  address: string
  size: string
  about: string
  date_joined: string
  phone_number?: string
  country: string
  banner?: any
}
export interface PasswordDTO {
  old_password: string
  new_password: string
}

export interface IAlumniProfile {
  id: number
  user_id?: number
  phone_number: string
  profile_pic: string
  last_seen: string
  last_posted?: string
  just_posted?: string
  skills: string[]
  job_title: string
  country: string
  region: string
  program: string
  cohort: number
  url: string
  video_bio: string
  work_availability:
    | 'Unavailable to work'
    | 'Open to offers'
    | 'Loooking for offers'
  website: string
  twitter: string
  figma: string
  instagram: string
  facebook: string
  linkedin: string
  viewing_companies: number[]
  first_name: string
  last_name: string
  date_joined: string
  email: string
  last_updated?: string
  bio_viewers?: number[]
  profile_completion?: number
  bio_views?: number
  total_jobs_applied?: number
  total_engagements?: number
  relevant_skills?: string[]
  skill_score?: number
  job_preference_score?: number
  work_experience_score?: number
  education_score?: number
  portfolio_score?: number
  engagement?: IEngagement
  years_of_experience?: number
  hired_application?: IApplication
  rejected_application?: IApplication
  viewing_industries: IViewingIndustry[]
  average_rating: IRecruiterRating
  world_ranking?: number
  cohort_ranking?: number
  gender: string
  company_role?: string
  preferred_language?: string
  filename?: string
  cv?: any
}

interface IViewingIndustry {
  name: string
  count: number
  degree?: number
  companies: string[]
}

interface IApplication {
  job: string
  company: string
  recruiter: string
  date_hired?: string
  status?: string
  date_rejected?: string
  stage?: string
}

interface IEngagement {
  status: string
  company: string
  recruiter: string
  last_engaged: string
  date_engaged: string
}
export interface IPreference {
  id: number
  work_roles: string[]
  work_preference: string[]
  company_size: string[]
  industry: string[]
  languages_spoken: string[]
  min_salary_amount: string
  salary_currency: Currency
  salary_interval: 'month' | 'hour' | 'year'
  country: string
}
export enum Currency {
  'LEK Lek' = 'LEK Lek',
  'AFN ؋' = 'AFN ؋',
  'ARS $' = 'ARS $',
  'USD $' = 'USD $',
  'AWG ƒ' = 'AWG ƒ',
  'AUD $' = 'AUD $',
  'AZN ₼' = 'AZN ₼',
  'BSD $' = 'BSD $',
  'BBD $' = 'BBD $',
  'BYN Br' = 'BYN Br',
  'BZD BZ$' = 'BZD BZ$',
  'BMD $' = 'BMD $',
}
export const Countries = [
  'Burkina Faso',
  'Côte d’Ivoire',
  'Democratic Republic of Congo',
  'Eswatini',
  'Gabon',
  'Ghana',
  'Kenya',
  'Mauritius',
  'Nigeria',
  'Rwanda',
  'Senegal',
  'South Africa',
  'Tanzania',
  'Uganda',
  'Zambia',
  'Zimbabwe',
]
export interface IWorkExperience {
  id: number
  cv_start_date: string
  cv_end_date: string
  company: string
  role: string
  description: string
  location: string
  employment_type: string
  start_date: string
  end_date: string
  currently_working_here: boolean
  img: string
}
export interface IEducation {
  id: number
  cv_start_date: string
  cv_end_date: string
  institution: string
  address: string
  field_of_study: string
  degree: string
  start_date: string
  end_date: string
  currently_studying_here: boolean
}
export interface IAdminEducationProfile {
  id: number
  cv_start_date: string
  cv_end_date: string
  institution: string
  address: string
  field_of_study: string
  degree: string
  start_date: string
  end_date: string
  currently_studying_here: boolean
}
export interface IPortfolio {
  id: number
  images: Image[]
  links: string[]
  title: string
  description: string
  created_at: string
  updated_at: string
  views: number
}
export interface IAdminAlumniPortfolio {
  results: [
    {
      id: number
      images: Image[]
      links: string[]
      title: string
      description: string
      created_at: string
      updated_at: string
      views: number
    }
  ]
}

export interface Image {
  id?: number
  image: string
  default: boolean
  src?: string
  file?: File
  created_at?: string
  updated_at?: string
  portfolio: number
}

export const changePassword = async (data: PasswordDTO) => {
  return await api.post<{ data: PasswordDTO }>(CHANGE_PASSWORD, data)
}
export const getAdminProfile = async () => {
  return (await api.get<{ data: IAdminProfile }>(ADMIN_PROFILE)).data
}
export const updateAdminProfile = async (data: FormData) => {
  return await api.patch<{ data: IAdminProfile }>(ADMIN_PROFILE, data)
}

export const getRecruiterProfile = async () => {
  return (await api.get<{ data: IRecruiterProfile }>(RECRUITER_PROFILE)).data
}
export const updateRecruiterProfile = async (data: FormData) => {
  return await api.patch<{ data: IRecruiterProfile }>(RECRUITER_PROFILE, data)
}
export const updateRecruiterCompany = async (data: Partial<Company>) => {
  const formData = new FormData()
  Object.entries(data).forEach(([key, value]) => {
    formData.set(key, value)
  })
  return await api.patch<{ data: Company }>(RECRUITER_COMPANY, formData)
}

export const getAlumniProfile = async (id: number) => {
  return (await api.get<{ data: IAlumniProfile }>(`${ALUMNI_PROFILE}${id}/`))
    .data
}
export const getAlumniPreferences = async (payload: ProfilePageDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: PaginatedData<IPreference> }>(
      `${ALUMNI_PROFILE}job-preference/?${qs}`
    )
  ).data
}
export const getAdminAlumniProfile = async (id: number) => {
  return (
    await api.get<{ data: IPreference }>(
      `${ALUMNI_PROFILE}${id}/job-preference/`
    )
  ).data
}
export const getAlumniDashboardDetails = async (id: number) => {
  return (
    await api.get<{ data: IAlumniProfile }>(`${ALUMNI_PROFILE}${id}/dashboard/`)
  ).data
}
export const getAdminAlumniEducation = async (id: number) => {
  return (
    await api.get<{ data: PaginatedData<IAdminEducationProfile> }>(
      `${ALUMNI_PROFILE}${id}/education/`
    )
  ).data
}
export const getAdminAlumniPortfolio = async (id: number) => {
  return (
    await api.get<{ data: PaginatedData<IPortfolio> }>(
      `${ALUMNI_PROFILE}${id}/portfolios/`
    )
  ).data
}
export const getAdminAlumniWorkExperience = async (id: number) => {
  return (
    await api.get<{ data: PaginatedData<IWorkExperience> }>(
      `${ALUMNI_PROFILE}${id}/work-experiences/`
    )
  ).data
}

export const getAlumniWorkExperience = async (payload: ProfilePageDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: PaginatedData<IWorkExperience> }>(
      `${ALUMNI_PROFILE}work-experience/?${qs}`
    )
  ).data
}
export const getAlumniEducation = async (payload: ProfilePageDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: PaginatedData<IEducation> }>(
      `${ALUMNI_PROFILE}education/?${qs}`
    )
  ).data
}
export const getAlumniPortfolio = async (payload: ProfilePageDTO) => {
  const qs = addDataToQueryString('', payload)
  return (
    await api.get<{ data: PaginatedData<IPortfolio> }>(
      `${ALUMNI_PROFILE}portfolio/?${qs}`
    )
  ).data
}

export const uploadAlumniResume = async (data: FormData) => {
  return await api.post<{ data: { cv: string } }>(`${ALUMNI_PROFILE}cv`, data)
}
export const downloadAlumniResume = async () => {
  return await api.get(`${ALUMNI_PROFILE}cv`)
}

export const updateAlumniProfile = async ({
  data,
  id,
}: {
  data: FormData
  id: number
}) => {
  return await api.patch<{ data: IAlumniProfile }>(
    `${ALUMNI_PROFILE}${id}/`,
    data
  )
}

export const createAlumniPreferences = async (data: IPreference) => {
  return await api.post<{ data: IPreference }>(
    `${ALUMNI_PROFILE}job-preference/`,
    data
  )
}
export const updateAlumniPreferences = async ({
  data,
  id,
}: {
  data: Partial<IPreference>
  id: number
}) => {
  return await api.patch<{ data: IPreference }>(
    `${ALUMNI_PROFILE}job-preference/${id}/`,
    data
  )
}
export const createAlumniWorkExperience = async (data: FormData) => {
  return await api.post<{ data: IWorkExperience }>(
    `${ALUMNI_PROFILE}work-experience/`,
    data
  )
}
export const updateAlumniWorkExperience = async ({
  data,
  id,
}: {
  data: FormData
  id: number
}) => {
  return await api.patch<{ data: IWorkExperience }>(
    `${ALUMNI_PROFILE}work-experience/${id}/`,
    data
  )
}
export const deleteAlumniWorkExperience = async (id: number) => {
  return await api.delete(`${ALUMNI_PROFILE}work-experience/${id}/`)
}
export const createAlumniEducation = async (data: IEducation) => {
  return await api.post<{ data: IEducation }>(
    `${ALUMNI_PROFILE}education/`,
    data
  )
}
export const updateAlumniEducation = async ({
  data,
  id,
}: {
  data: Partial<IEducation>
  id: number
}) => {
  return await api.patch<{ data: IEducation }>(
    `${ALUMNI_PROFILE}education/${id}/`,
    data
  )
}
export const deleteAlumniEducation = async (id: number) => {
  return await api.delete(`${ALUMNI_PROFILE}education/${id}/`)
}
export const createAlumniPortfolio = async (data: FormData) => {
  return await api.post<{ data: IPortfolio }>(
    `${ALUMNI_PROFILE}portfolio/`,
    data
  )
}
export const updateAlumniPortfolio = async ({
  data,
  id,
}: {
  data: FormData
  id: number
}) => {
  return await api.patch<{ data: IPortfolio }>(
    `${ALUMNI_PROFILE}portfolio/${id}/`,
    data
  )
}
export const deleteAlumniPortfolio = async (id: number) => {
  return await api.delete(`${ALUMNI_PROFILE}portfolio/${id}/`)
}
export const createAlumniPortfolioImage = async (data: FormData) => {
  return await api.post<{ data: Image }>(
    `${ALUMNI_PROFILE}portfolio/image/`,
    data
  )
}
export const updateAlumniPortfolioImage = async ({
  data,
  id,
}: {
  data: FormData
  id: number
}) => {
  return await api.patch<{ data: Image }>(
    `${ALUMNI_PROFILE}portfolio/image/${id}/`,
    data
  )
}
export const deleteAlumniPortfolioImage = async (id: number) => {
  return await api.delete(`${ALUMNI_PROFILE}portfolio/image/${id}/`)
}
export const getAllIndustries = async () => {
  return await api.get(`${GET_ALL_INDUSTRIES}/`)
}

export const getAlumniProfileScore = async (id: any) => {
  return await api.get(`${ALUMNI_PROFILE}${id}/profile-completion/`)
}
