import { getAlumniInCohort, getAlumniOnline } from './../apis/alumniApis'
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  DashboardData,
  AlumniListDTO,
  getAllAlumniData,
  getRegisteredAlumniData,
  getRejectedAlumniData,
  getEngagedAlumniData,
  getAlumniProfileCompletionData,
  getHiredAlumniData,
  AlumniList,
  downloadAlumniData,
  downloadOption,
  getBlockedAlumniData,
  getAlumniFilters,
  IFilter,
  CompanyFilter,
} from '@/apis/alumniApis'
import { IAlumniProfile } from '@/apis/profileApis'
import { PaginatedData } from '@/apis/api.types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
const initialState: {
  filters: IFilter
} = {
  filters: {
    company: [],
  },
}
export const alumniApiSlice = createApi({
  reducerPath: 'alumniApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: [
    'Alumni',
    'Recruiter',
    'AllAlumni',
    'RegiseredAlumni',
    'BlockedAlumni',
    'RejectedAlumni',
    'EngagedAlumni',
    'HiredAlumni',
    'AlumniProfileCompletion',
    'AlumniInCohort',
    'AlumniOnline',
  ],

  endpoints: (builder) => ({
    getAllAlumniData: builder.query<any, AlumniListDTO>({
      queryFn: async (data) => {
        try {
          return await getAllAlumniData(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['AllAlumni'],
    }),
    getRegisteredAlumniData: builder.query<any, AlumniListDTO>({
      queryFn: async (data) => {
        try {
          return await getRegisteredAlumniData(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['RegiseredAlumni'],
    }),
    getBlockedAlumniData: builder.query<any, AlumniListDTO>({
      queryFn: async (data) => {
        try {
          return await getBlockedAlumniData(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['BlockedAlumni'],
    }),
    getRejectedAlumniData: builder.query<
      PaginatedData<IAlumniProfile>,
      AlumniListDTO
    >({
      queryFn: async (data) => {
        try {
          return await getRejectedAlumniData(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['RejectedAlumni'],
    }),
    getEngagedAlumniData: builder.query<any, AlumniListDTO>({
      queryFn: async (data) => {
        try {
          return await getEngagedAlumniData(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['EngagedAlumni'],
    }),

    getHiredAlumniData: builder.query<
      PaginatedData<IAlumniProfile>,
      AlumniListDTO
    >({
      queryFn: async (data) => {
        try {
          return await getHiredAlumniData(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['HiredAlumni'],
    }),

    alumniFilters: builder.query<IFilter, void>({
      queryFn: async (data) => {
        try {
          return await getAlumniFilters()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Recruiter'],
    }),

    getAlumniInCohort: builder.query<
      PaginatedData<IAlumniProfile>,
      AlumniListDTO
    >({
      queryFn: async (data) => {
        try {
          return await getAlumniInCohort(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['AlumniInCohort'],
    }),
    getAlumniOnline: builder.query<
      PaginatedData<IAlumniProfile>,
      AlumniListDTO
    >({
      queryFn: async (data) => {
        try {
          return await getAlumniOnline(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['AlumniOnline'],
    }),
    getAlumniProfileCompletionData: builder.query<any, AlumniListDTO>({
      queryFn: async (data) => {
        try {
          return await getAlumniProfileCompletionData(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['AlumniProfileCompletion'],
    }),
  }),
})

export const {
  useGetAllAlumniDataQuery,
  useGetRegisteredAlumniDataQuery,
  useGetBlockedAlumniDataQuery,
  useGetRejectedAlumniDataQuery,
  useGetEngagedAlumniDataQuery,
  useGetAlumniProfileCompletionDataQuery,
  useGetHiredAlumniDataQuery,
  useGetAlumniOnlineQuery,
  useAlumniFiltersQuery,
  useGetAlumniInCohortQuery,
} = alumniApiSlice

export const recruiterSlice = createSlice({
  name: 'recruiter',
  initialState,
  reducers: {
    resetFilters(state) {
      state.filters = initialState.filters
    },

    updateFilters(state, action: PayloadAction<Partial<IFilter>>) {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
  },
})
export const { resetFilters, updateFilters } = recruiterSlice.actions

export default recruiterSlice.reducer
