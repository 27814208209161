import { number, object, string } from 'yup'

const jobSchema = object().shape({
  pipeline: number().min(1, 'Please Select a pipeline').required(),
  description: string().min(3).required('Specify a short Description'),
  title: string().min(2).required('Enter a representative title'),
  job_type: string().min(3).required('Select a job type'),
  work_policy: string().min(3).required('Specify a work policy'),
  salary_currency: string().required(),
  salary_range: string().required(),
  years_of_experience: number()
    .min(1, 'Enter a valid number of experience')
    .required(),
  location: string().min(2).required(),
})

export default jobSchema
