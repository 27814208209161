import {
  Activity,
  createRating,
  deleteJobImage,
  getHiredAlumni,
  getPipelineActivities,
  RatingDTO,
  recruiterMoveAlumniAcrossPipeline,
} from './../apis/recruitementApis'
import { usePrefetch } from '@/store/authSlice'
import {
  JobSearchDTO,
  getRecruiterPostedJobs,
  CreateJobDTO,
  recruiterPostJob,
  getJobFilters,
  IFilter,
  Job,
  Pipeline,
  getJobsPipeline,
  createPipeline,
  Stage,
  StageResponse,
  addStageToPipeline,
  StageOrder,
  updateStageOrder,
  updateStage,
  deleteStage,
  updatePipeline,
  getJobPipeline,
  getJob,
  updateRecruiterJob,
  deleteRecruiterJob,
  JobMatchDTO,
  getJobMatches,
  Applicant,
  getJobApplication,
  JobApplicantDTO,
  getJobsApplications,
  alumniApplyForJob,
  MoveAlumniDTO,
  recruiterMoveAlumniToPipeline,
  recruiterRejectsApplication,
  getRecruitmentMetrics,
  ALUMNI,
  getAlumni,
  ApplicantSummary,
  recruiterHireAlumni,
} from '@/apis/recruitementApis'
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PaginatedData } from '@/apis/api.types'
import { invalidateProfileApiSlice } from './profileSlice'
import { getProfileCompletionValue } from '@/apis/recruitersApis'
import {
  IRecruiterProfile,
  IRecruiterProfileComplete,
} from '@/apis/profileApis'

type Filters = {
  job_type: string[]
  salary_range: string[]
  work_policy: string[]
  company_size: string[]
  industry: string[]
  position: string
  location: string
}
const initialState: {
  selectedJob: number
  filters: Filters
} = {
  selectedJob: 0,
  filters: {
    job_type: [],
    salary_range: [],
    work_policy: [],
    company_size: [],
    industry: [],
    position: '',
    location: '',
  },
}

export const recruitmentApiSlice = createApi({
  reducerPath: 'recruitmentApi',
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Recruitment', 'Jobs', 'Pipeline', 'Profile', 'complete'],

  endpoints: (builder) => ({
    recruiterJobsPosting: builder.query<PaginatedData<Job>, JobSearchDTO>({
      queryFn: async (data) => {
        try {
          return await getRecruiterPostedJobs(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Jobs'],
    }),
    jobFilters: builder.query<IFilter, void>({
      queryFn: async (data) => {
        try {
          return await getJobFilters()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Jobs'],
    }),
    recruiterCreateJob: builder.mutation<
      { data: CreateJobDTO & { id: number } },
      FormData
    >({
      queryFn: async (payload) => {
        try {
          return await recruiterPostJob(payload)
        } catch (error) {
          throw new Error((error as any).response?.data?.message)
        }
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(invalidateProfileApiSlice(['Profile']))
      },
      invalidatesTags: ['Jobs'],
    }),
    deleteJobImage: builder.mutation<{ data: any }, { id: number }>({
      queryFn: async (payload) => {
        try {
          return await deleteJobImage(payload)
        } catch (error) {
          throw new Error((error as any).response?.data?.message)
        }
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(invalidateProfileApiSlice(['Profile']))
      },
      invalidatesTags: ['Jobs'],
    }),
    getJob: builder.query<Job, number>({
      queryFn: async (data) => {
        try {
          return await getJob(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Jobs'],
    }),
    getJobApplication: builder.query<Applicant, number>({
      queryFn: async (data) => {
        try {
          return await getJobApplication(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Jobs'],
    }),
    getJobsApplications: builder.query<
      PaginatedData<Applicant>,
      JobApplicantDTO
    >({
      queryFn: async (data) => {
        try {
          return await getJobsApplications(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Jobs'],
    }),
    getRecruitmentMetrics: builder.query<any, void>({
      queryFn: async (data) => {
        try {
          return await getRecruitmentMetrics()
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Jobs'],
    }),
    alumniApplyForJob: builder.mutation<{ data: Applicant }, number>({
      queryFn: async (payload) => {
        try {
          return await alumniApplyForJob(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Jobs'],
    }),
    moveAlumniToPipeline: builder.mutation<
      { data: MoveAlumniDTO },
      MoveAlumniDTO
    >({
      queryFn: async (payload) => {
        try {
          return await recruiterMoveAlumniToPipeline(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Jobs', 'Pipeline'],
    }),
    moveAlumniAcrossPipeline: builder.mutation<
      { data: MoveAlumniDTO },
      MoveAlumniDTO
    >({
      queryFn: async (payload) => {
        try {
          return await recruiterMoveAlumniAcrossPipeline(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Jobs', 'Pipeline', 'Recruitment'],
    }),
    getHiredAlumni: builder.query<
      PaginatedData<ALUMNI>,
      { page: number; page_size?: number; company: number[] }
    >({
      queryFn: async (data) => {
        try {
          return await getHiredAlumni(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Jobs'],
    }),
    hireAlumni: builder.mutation<{ data: MoveAlumniDTO }, MoveAlumniDTO>({
      queryFn: async (payload) => {
        try {
          return await recruiterHireAlumni(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(invalidateProfileApiSlice(['Profile']))
      },
      invalidatesTags: ['Jobs', 'Profile', 'Recruitment', 'Pipeline'],
    }),
    recruiterRateAlumni: builder.mutation<{ data: RatingDTO }, RatingDTO>({
      queryFn: async (payload) => {
        try {
          return await createRating(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Jobs', 'Pipeline'],
    }),
    recruiterRejectsApplication: builder.mutation<
      { data: any },
      { job: number; message?: string }
    >({
      queryFn: async (payload) => {
        try {
          return await recruiterRejectsApplication(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Jobs', 'Pipeline'],
    }),
    updateRecruiterJob: builder.mutation<
      { data: CreateJobDTO & { id: number } },
      FormData
    >({
      queryFn: async (payload) => {
        try {
          return await updateRecruiterJob(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Jobs'],
    }),
    deleteRecruiterJob: builder.mutation<Job, number>({
      queryFn: async (payload) => {
        try {
          return await deleteRecruiterJob(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled
        dispatch(invalidateProfileApiSlice(['Profile']))
      },
      invalidatesTags: ['Jobs'],
    }),
    jobMatches: builder.query<PaginatedData<ApplicantSummary>, JobMatchDTO>({
      queryFn: async (data) => {
        try {
          return await getJobMatches(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Jobs'],
    }),
    jobPipelines: builder.query<PaginatedData<Pipeline>, JobSearchDTO>({
      queryFn: async (data) => {
        try {
          return await getJobsPipeline(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Pipeline'],
    }),
    jobPipeline: builder.query<Pipeline, { id: number }>({
      queryFn: async (data) => {
        try {
          return await getJobPipeline(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Pipeline'],
    }),
    pipelinActivities: builder.query<
      PaginatedData<Activity>,
      { pipeline: number; page: number }
    >({
      queryFn: async (data) => {
        try {
          return await getPipelineActivities(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Pipeline'],
    }),

    createJobPipeline: builder.mutation<{ data: Pipeline }, { name: string }>({
      queryFn: async (payload) => {
        try {
          return await createPipeline(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Pipeline', 'Profile'],
    }),
    updateJobPipeline: builder.mutation<
      { data: Pipeline },
      { name: string; id: number }
    >({
      queryFn: async (payload) => {
        try {
          return await updatePipeline(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Pipeline'],
    }),
    addStageToPipeline: builder.mutation<{ data: StageResponse }, Stage>({
      queryFn: async (payload) => {
        try {
          return await addStageToPipeline(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Pipeline'],
    }),
    moveStage: builder.mutation<{ data: StageOrder }, StageOrder>({
      queryFn: async (payload) => {
        try {
          return await updateStageOrder(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Pipeline'],
    }),
    updateStage: builder.mutation<
      { data: StageResponse },
      Stage & { id: number }
    >({
      queryFn: async (payload) => {
        try {
          return await updateStage(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Pipeline'],
    }),
    deleteStage: builder.mutation<unknown, { id: number }>({
      queryFn: async (payload) => {
        try {
          return await deleteStage(payload)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      invalidatesTags: ['Pipeline'],
    }),
    getProfileCompletionValue: builder.query<IRecruiterProfileComplete, number>(
      {
        queryFn: async (id) => {
          try {
            return await getProfileCompletionValue(id)
          } catch (error) {
            throw new Error((error as any).response.data.message)
          }
        },
        providesTags: ['complete'],
      }
    ),
    getAlumni: builder.query<ALUMNI, { id: number }>({
      queryFn: async (data) => {
        try {
          return await getAlumni(data)
        } catch (error) {
          throw new Error((error as any).response.data.message)
        }
      },
      providesTags: ['Recruitment'],
    }),
  }),
})

export const {
  usePrefetch: usePrefetchRecruitment,
  useRecruiterJobsPostingQuery,
  useJobFiltersQuery,
  useRecruiterCreateJobMutation,
  useDeleteJobImageMutation,
  useGetJobQuery,
  useGetRecruitmentMetricsQuery,
  useMoveAlumniToPipelineMutation,
  useGetHiredAlumniQuery,
  useHireAlumniMutation,
  useRecruiterRejectsApplicationMutation,
  useRecruiterRateAlumniMutation,
  useGetJobApplicationQuery,
  useGetJobsApplicationsQuery,
  useAlumniApplyForJobMutation,
  useUpdateRecruiterJobMutation,
  useDeleteRecruiterJobMutation,
  useJobPipelinesQuery,
  useJobPipelineQuery,
  useCreateJobPipelineMutation,
  useUpdateJobPipelineMutation,
  useUpdateStageMutation,
  useAddStageToPipelineMutation,
  useDeleteStageMutation,
  useMoveStageMutation,
  useJobMatchesQuery,
  useGetAlumniQuery,
  usePipelinActivitiesQuery,
  useMoveAlumniAcrossPipelineMutation,
  useGetProfileCompletionValueQuery,
} = recruitmentApiSlice

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    resetFilters(state) {
      state.filters = initialState.filters
    },
    setSelectedJob(state, action) {
      state.selectedJob = action.payload
      console.log(state.selectedJob, action.payload, 'RESULT')
    },
    updateFilters(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
  },
})

export const { resetFilters, setSelectedJob, updateFilters } = jobsSlice.actions

export default jobsSlice.reducer
